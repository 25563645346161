<template>
  <BaseCard
    :footer-visible="false"
    :caption-visible="false"
  >
    <template #title>
      <span class="h3 font-weight-bold mb-0 text-uppercase">Informes de certificaciones</span>
    </template>
    <CertificationReportsTable />
  </BaseCard>
</template>

<script>
import CertificationReportsTable from '@/components/certification-reports/table/CertificationReportsTable.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'

export default {
  components: { BaseCard, CertificationReportsTable },
}
</script>

<style scoped>
</style>
